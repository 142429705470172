import React, { useState, useEffect } from 'react';
import { Box, Image, Text, HStack, VStack, Icon, Button } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import axios from 'axios';

// Placeholder Image Component
const PlaceholderImage = () => (
  <Box
    bg="gray.700"
    w="100%"
    h="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Text
      color="gray.500"
      fontSize="sm"
      fontWeight="medium"
      textAlign="center"
    >
      NO IMAGE
      <br />
      AVAILABLE
    </Text>
  </Box>
);

// GroupBadge Component for displaying grouped users
const GroupBadge = ({ label, colorScheme, isGrayscale = false }) => {
  const { bg, hoverBg } = isGrayscale
    ? { bg: 'gray.400', hoverBg: 'gray.500' }
    : colorScheme;

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      bg={bg}
      px={3}
      h="22px"
      borderRadius="md"
      mr={1}
      transition="all 0.2s"
      _hover={{ bg: hoverBg }}
      opacity={isGrayscale ? '0.7' : '1'}
    >
      <Text fontSize="xs" fontWeight="bold" color="black">
        {label}
      </Text>
    </Box>
  );
};

// User Initial Badge Component
const UserInitialBadge = ({ initial, isGrayscale = false }) => {
  const lilacGroup = ['M', 'Y', 'S'];
  const mintGroup = ['W', 'Z', 'R'];

  const getBadgeColor = (initial) => {
    if (isGrayscale) {
      return {
        bg: 'gray.400',
        hoverBg: 'gray.500',
      };
    }
    if (lilacGroup.includes(initial)) {
      return {
        bg: 'purple.200',
        hoverBg: 'purple.300',
      };
    }
    return {
      bg: 'green.200',
      hoverBg: 'green.300',
    };
  };

  const { bg, hoverBg } = getBadgeColor(initial);

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      bg={bg}
      w="22px"
      h="22px"
      borderRadius="md"
      mr={1}
      transition="all 0.2s"
      _hover={{ bg: hoverBg }}
      opacity={isGrayscale ? '0.7' : '1'}
    >
      <Text fontSize="xs" fontWeight="bold" color="black">
        {initial}
      </Text>
    </Box>
  );
};

// Color schemes for different groups
const COLORS = {
  MINT: {
    bg: 'green.200',
    hoverBg: 'green.300',
  },
  LILAC: {
    bg: 'purple.200',
    hoverBg: 'purple.300',
  },
  LIGHT_BLUE: {
    bg: 'blue.200',
    hoverBg: 'blue.300',
  },
};

// Groups configuration for matching initials
const USER_GROUPS = {
  PARENTS: {
    members: ['W', 'M'],
    colors: COLORS.LILAC,
    label: 'Parents',
  },
  ADULTS: {
    members: ['Z', 'R'],
    colors: COLORS.MINT,
    label: 'Adults',
  },
  KIDS: {
    members: ['S', 'Y'],
    colors: COLORS.LIGHT_BLUE,
    label: 'Kids',
  },
};

// Function to display grouped or individual badges
const getUserBadges = (wantedBy, isGrayscale) => {
  if (!Array.isArray(wantedBy) || !wantedBy.length) {
    return [];
  }

  // Get user initials
  const initials = wantedBy
    .filter((user) => user && user.username)
    .map((user) => user.username.charAt(0).toUpperCase());

  const groupBadges = [];
  const remainingInitials = [...initials];

  // Helper to check if all initials in a group exist
  const addGroupBadgeIfApplicable = (group) => {
    if (group.members.every((initial) => remainingInitials.includes(initial))) {
      groupBadges.push(
        <GroupBadge
          key={group.label}
          label={group.label}
          colorScheme={group.colors}
          isGrayscale={isGrayscale}
        />
      );
      // Remove initials from remainingInitials
      group.members.forEach((initial) => {
        const index = remainingInitials.indexOf(initial);
        if (index !== -1) remainingInitials.splice(index, 1);
      });
    }
  };

  // Check for each group
  addGroupBadgeIfApplicable(USER_GROUPS.PARENTS);
  addGroupBadgeIfApplicable(USER_GROUPS.ADULTS);
  addGroupBadgeIfApplicable(USER_GROUPS.KIDS);

  // Add remaining individual initials as badges
  const individualBadges = remainingInitials.map((initial, index) => (
    <UserInitialBadge key={index} initial={initial} isGrayscale={isGrayscale} />
  ));

  return [...groupBadges, ...individualBadges];
};

const ItemCard = ({ item, onClick, onStatusChange, onWantItem }) => {
  const isHave = item.status === 'Have';
  const [showConfirm, setShowConfirm] = useState(false);
  const [isWanting, setIsWanting] = useState(false);
  const [storeShortName, setStoreShortName] = useState('');
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  
  // Fetch current userId from localStorage
  const currentUserId = localStorage.getItem('userId');
  console.log('Current User ID:', currentUserId); // Debugging

  // Real-time updates for 'wantedBy'
  const [wantedBy, setWantedBy] = useState(item.wantedBy);
  useEffect(() => {
    setWantedBy(item.wantedBy);
  }, [item.wantedBy]);

  // Fetch store settings including short name
  useEffect(() => {
    const fetchStoreSettings = async () => {
      try {
        const apiBaseUrl = process.env.NODE_ENV === 'production'
          ? 'https://list.gqfam.com/api'
          : 'http://localhost:7000/api';

        const response = await axios.get(`${apiBaseUrl}/stores/${item.store._id}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setStoreShortName(response.data.shortName || item.store.storeName);
      } catch (error) {
        console.error('Error fetching store settings:', error);
        setStoreShortName(item.store.storeName);
      }
    };

    if (item.store?._id) {
      fetchStoreSettings();
    }
  }, [item.store]);

  // Update the userWantsItem check
  const userWantsItem = wantedBy?.some(user => {
    const userId = typeof user === 'object' ? user._id : user;
    console.log('Comparing user:', { userId, currentUserId });
    return userId === currentUserId;
  });

  const handleStatusClick = (e) => {
    e.stopPropagation();
    if (isHave) {
      onStatusChange(item._id, 'Need');
      setShowConfirm(false);
    } else {
      if (showConfirm) {
        onStatusChange(item._id, 'Have');
        setShowConfirm(false);
      } else {
        setShowConfirm(true);
      }
    }
  };

  const handleWantClick = async (e) => {
    e.stopPropagation();
    setIsWanting(true);
    try {
      const apiBaseUrl = process.env.NODE_ENV === 'production'
        ? 'https://list.gqfam.com/api'
        : 'http://localhost:7000/api';

      console.log('Sending want request for item:', item._id);
      const response = await axios.post(
        `${apiBaseUrl}/items/${item._id}/want`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      console.log('Want response:', response.data);

      // Update local state immediately
      setWantedBy(response.data.wantedBy);

      if (onWantItem) {
        onWantItem(response.data);
      }
    } catch (error) {
      console.error('Error updating want status:', error);
    } finally {
      setIsWanting(false);
    }
  };

  const handleUnwantClick = async (e) => {
    e.stopPropagation();
    setIsWanting(true);
    try {
      const apiBaseUrl = process.env.NODE_ENV === 'production'
        ? 'https://list.gqfam.com/api'
        : 'http://localhost:7000/api';

      const response = await axios.post(
        `${apiBaseUrl}/items/${item._id}/unwant`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      console.log('Unwant response:', response.data);

      // Update local state immediately
      setWantedBy(response.data.wantedBy);

      if (onWantItem) {
        onWantItem(response.data);
      }
    } catch (error) {
      console.error('Error removing want status:', error);
    } finally {
      setIsWanting(false);
    }
  };

  const handleCardClick = (e) => {
    setShowConfirm(false);
    onClick(e);
  };

  // Render the action button based on item status and user role
  const renderActionButton = () => {
    if (isHave) {
      return (
        <Button
          height="32px"
          width="130px"
          onClick={handleStatusClick}
          colorScheme="green"
          variant="solid"
          fontSize="sm"
          fontWeight="bold"
          boxShadow="md"
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: 'lg',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'sm',
          }}
        >
          Need Item
        </Button>
      );
    } else if (isAdmin) {
      return (
        <Button
          height="32px"
          width="130px"
          onClick={handleStatusClick}
          colorScheme={showConfirm ? 'yellow' : 'red'}
          variant="solid"
          fontSize="sm"
          fontWeight="bold"
          boxShadow="md"
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: 'lg',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'sm',
          }}
        >
          {showConfirm ? 'Confirm Have?' : 'Have Item'}
        </Button>
      );
    } else if (!isHave) {
      return (
        <Button
          height="32px"
          width="130px"
          onClick={userWantsItem ? handleUnwantClick : handleWantClick}
          isLoading={isWanting}
          colorScheme={userWantsItem ? 'red' : 'blue'}
          variant={userWantsItem ? 'solid' : 'outline'}
          fontSize="sm"
          fontWeight="bold"
          boxShadow="md"
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: 'lg',
            bg: userWantsItem ? 'red.600' : undefined,
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'sm',
          }}
        >
          {userWantsItem ? "Don't Want This" : 'I Want This'}
        </Button>
      );
    }
    return null;
  };

  return (
    <Box
      w="100%"
      borderRadius="lg"
      overflow="hidden"
      bg="gray.850"
      borderWidth="1px"
      borderColor="gray.700"
      cursor="pointer"
      transition="all 0.2s ease"
      _hover={{
        transform: 'translateY(-2px)',
        boxShadow: 'dark-lg',
        borderColor: 'gray.600',
      }}
      position="relative"
      mb={4}
    >
      <Box
        w="100%"
        h="4px"
        bg={isHave ? 'gray.500' : item.store.color}
        filter={isHave ? 'grayscale(100%)' : 'none'}
      />

      <HStack spacing={0} align="stretch" h="140px">
        <Box
          w="140px"
          position="relative"
          flexShrink={0}
          borderRight="1px"
          borderColor="gray.700"
          filter={isHave ? 'grayscale(100%)' : 'none'}
          sx={{
            '& > *': {
              filter: isHave ? 'grayscale(100%)' : 'none',
            },
          }}
        >
          <Box position="relative" width="100%" height="100%" opacity={isHave ? '0.5' : '1'}>
            {!item.imageURL ? (
              <PlaceholderImage />
            ) : (
              <Image
                src={item.imageURL}
                alt={item.itemName}
                objectFit="cover"
                w="100%"
                h="100%"
                fallback={<PlaceholderImage />}
              />
            )}
          </Box>
          {!isHave && (
            <Box
              position="absolute"
              top={2}
              right={2}
              bg={item.store.color}
              color="white"
              px={2}
              py={1}
              borderRadius="md"
              fontSize="xs"
              textTransform="uppercase"
              fontWeight="bold"
              boxShadow="md"
              borderWidth="2px"
              borderColor="black"
              letterSpacing="0.5px"
            >
              {storeShortName}
            </Box>
          )}
        </Box>

        <VStack
          align="start"
          p={3}
          spacing={1}
          flex={1}
          h="100%"
          justify="space-between"
          onClick={handleCardClick}
        >
          <VStack align="start" spacing={1} w="100%">
            <Text
              fontSize="lg"
              fontWeight="semibold"
              color="white"
              lineHeight="tight"
              noOfLines={1}
              opacity={isHave ? '0.7' : '1'}
            >
              {item.itemName}
            </Text>

            {renderActionButton()}
          </VStack>

          <HStack spacing={1} color="gray.400" height="20px" align="center">
            <Icon as={InfoIcon} w={4} h={4} />
            <Box>
              <HStack spacing={1}>
                {getUserBadges(wantedBy, isHave)}
              </HStack>
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default ItemCard;
