import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import axios from 'axios';

const AddItem = ({ onItemAdded }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stores, setStores] = useState([]);
  const [newItem, setNewItem] = useState({
    store: '',
    itemName: '',
    imageURL: '',
  });
  const toast = useToast();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/stores`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStores(res.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
      toast({
        title: "Error fetching stores",
        description: "Unable to load stores. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(`${apiBaseUrl}/items`, newItem, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      onItemAdded(res.data);
      onClose();
      setNewItem({ store: '', itemName: '', imageURL: '' });
      toast({
        title: "Item added successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });
  const modalSize = useBreakpointValue({ base: "full", md: "md" });

  return (
    <>
      <Box 
        display="flex" 
        alignItems="flex-end"
        width="100%"
      >
        <Button 
          onClick={onOpen} 
          colorScheme="blue" 
          size={buttonSize}
          width="100%"
          height="52px"
          mt={1}
          fontSize="lg"
          fontWeight="semibold"
          bg="blue.500"
          _hover={{ bg: 'blue.600' }}
        >
          Add New Item
        </Button>
      </Box>

      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        size={modalSize}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">Add New Item</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel color="white">Item Name</FormLabel>
                <Input 
                  name="itemName" 
                  value={newItem.itemName} 
                  onChange={handleInputChange} 
                  placeholder="Enter item name"
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel color="white">Store</FormLabel>
                <Select 
                  name="store" 
                  value={newItem.store} 
                  onChange={handleInputChange}
                  placeholder="Select a store"
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                  sx={{
                    '& option': {
                      color: 'white',
                      backgroundColor: 'gray.700'
                    }
                  }}
                >
                  {stores.map(store => (
                    <option 
                      key={store._id} 
                      value={store._id}
                      style={{ background: '#1a1a1a', color: 'white' }}
                    >
                      {store.storeName}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel color="white">Image URL</FormLabel>
                <Input 
                  name="imageURL" 
                  value={newItem.imageURL} 
                  onChange={handleInputChange}
                  placeholder="Enter image URL (optional)"
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={handleSubmit}
              size={buttonSize}
              bg="blue.500"
              _hover={{ bg: 'blue.600' }}
            >
              Add Item
            </Button>
            <Button 
              onClick={onClose}
              size={buttonSize}
              variant="ghost"
              color="white"
              _hover={{ bg: 'whiteAlpha.200' }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddItem;
