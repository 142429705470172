import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Image,
  Text,
  VStack,
  HStack,
  Box,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';

const DEFAULT_IMAGE = '/images/nia.png';

const ItemModal = ({ isOpen, onClose, item, onUpdate, onDelete }) => {
  const [editedItem, setEditedItem] = useState(item);
  const [stores, setStores] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [imageError, setImageError] = useState(false);
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const toast = useToast();
  const { 
    isOpen: isDeleteAlertOpen, 
    onOpen: onDeleteAlertOpen, 
    onClose: onDeleteAlertClose 
  } = useDisclosure();
  const cancelRef = React.useRef();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  useEffect(() => {
    setEditedItem(item);
    setImageError(false);
    fetchStores();
  }, [item]);

  const handleImageError = () => {
    setImageError(true);
  };

  const imageUrl = (!editedItem.imageURL || imageError) ? DEFAULT_IMAGE : editedItem.imageURL;

  const fetchStores = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/stores`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStores(res.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
      toast({
        title: "Error fetching stores",
        description: "Unable to load stores",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'imageURL') {
      setImageError(false);
    }
    if (name === 'store') {
      setEditedItem({
        ...editedItem,
        store: value
      });
    } else {
      setEditedItem({
        ...editedItem,
        [name]: value
      });
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await onDelete(item._id);
      onDeleteAlertClose();
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast({
        title: "Error deleting item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleWantThis = async () => {
    try {
      setIsUpdating(true);
      const response = await axios.post(
        `${apiBaseUrl}/items/${item._id}/want`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      setEditedItem(response.data);
      if (onUpdate) {
        await onUpdate(response.data);
      }
      toast({
        title: "Added to your wanted items",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding want",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      
      // Validate required fields
      if (!editedItem.itemName?.trim()) {
        toast({
          title: "Item name is required",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      if (!editedItem.store) {
        toast({
          title: "Store selection is required",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      // Perform the update
      await onUpdate(editedItem);
      
      toast({
        title: "Item updated successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      // Close the modal after successful update
      if (onClose) {
        onClose();
      }
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        closeOnOverlayClick={true}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">{editedItem.itemName}</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <VStack spacing={4}>
              <HStack width="100%" spacing={4} align="start">
                <VStack flex="1" spacing={4} align="stretch">
                  <FormControl isRequired>
                    <FormLabel color="white">Item Name</FormLabel>
                    <Input 
                      name="itemName" 
                      value={editedItem.itemName} 
                      onChange={handleInputChange} 
                      bg="gray.700"
                      color="white"
                      _hover={{ borderColor: 'purple.400' }}
                      _focus={{ 
                        borderColor: 'purple.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                      }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="white">Store</FormLabel>
                    <Select
                      name="store"
                      value={editedItem.store?._id || editedItem.store}
                      onChange={handleInputChange}
                      bg="gray.700"
                      color="white"
                      _hover={{ borderColor: 'purple.400' }}
                      _focus={{ 
                        borderColor: 'purple.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                      }}
                    >
                      {stores.map(store => (
                        <option 
                          key={store._id} 
                          value={store._id}
                          style={{ background: '#1a1a1a', color: 'white' }}
                        >
                          {store.storeName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="white">Image URL</FormLabel>
                    <Input 
                      name="imageURL" 
                      value={editedItem.imageURL || ''} 
                      onChange={handleInputChange}
                      placeholder="Enter image URL (optional)"
                      bg="gray.700"
                      color="white"
                      _hover={{ borderColor: 'purple.400' }}
                      _focus={{ 
                        borderColor: 'purple.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                      }}
                    />
                  </FormControl>
                </VStack>

                <Box width="120px" height="120px" flexShrink={0}>
                  <Image 
                    src={imageUrl}
                    alt={editedItem.itemName} 
                    objectFit="cover"
                    width="100%"
                    height="100%"
                    borderRadius="md"
                    onError={handleImageError}
                  />
                </Box>
              </HStack>

              <Button
                colorScheme="green"
                size="lg"
                width="100%"
                onClick={handleWantThis}
                isLoading={isUpdating}
                _hover={{ transform: 'translateY(-1px)', boxShadow: 'lg' }}
                _active={{ transform: 'translateY(1px)', boxShadow: 'sm' }}
              >
                I also want this!
              </Button>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <VStack spacing={2} width="100%">
              <HStack spacing={2} width="100%">
                <Button 
                  colorScheme="blue" 
                  onClick={onClose} 
                  flex={1}
                  _hover={{ transform: 'translateY(-1px)', boxShadow: 'lg' }}
                  _active={{ transform: 'translateY(1px)', boxShadow: 'sm' }}
                >
                  Back
                </Button>
                {isAdmin && (
                  <Button 
                    colorScheme="green" 
                    onClick={handleSave}
                    flex={1}
                    isLoading={isUpdating}
                    _hover={{ transform: 'translateY(-1px)', boxShadow: 'lg' }}
                    _active={{ transform: 'translateY(1px)', boxShadow: 'sm' }}
                  >
                    Save
                  </Button>
                )}
              </HStack>
              
              {isAdmin && (
                <Button 
                  colorScheme="red" 
                  onClick={onDeleteAlertOpen}
                  width="100%"
                  _hover={{ transform: 'translateY(-1px)', boxShadow: 'lg' }}
                  _active={{ transform: 'translateY(1px)', boxShadow: 'sm' }}
                >
                  Delete
                </Button>
              )}
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteAlertClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="gray.850">
            <AlertDialogHeader color="white">Delete Item</AlertDialogHeader>
            <AlertDialogBody color="white">
              Are you sure you want to delete "{editedItem.itemName}"? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button 
                ref={cancelRef} 
                onClick={onDeleteAlertClose}
                variant="ghost"
                color="white"
                _hover={{ bg: 'whiteAlpha.200' }}
              >
                Cancel
              </Button>
              <Button 
                colorScheme="red" 
                onClick={handleDelete} 
                ml={3}
                isLoading={isDeleting}
                _hover={{ bg: 'red.600' }}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ItemModal;
