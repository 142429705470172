import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Select,
  Text,
  VStack,
  HStack,
  useToast,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import io from 'socket.io-client';
import axios from 'axios';
import AddItem from './AddItem';
import ItemCard from './ItemCard';
import ItemModal from './ItemModal';

function PantryList() {
  const [items, setItems] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  // Socket.IO setup
  useEffect(() => {
    const socket = io(apiBaseUrl);

    socket.on('itemAdded', (newItem) => {
      if (newItem.status === 'Have') {
        setItems(prevItems => [...prevItems, newItem]);
      }
    });

    socket.on('itemUpdated', (updatedItem) => {
      setItems(prevItems => {
        if (updatedItem.status === 'Need') {
          return prevItems.filter(item => item._id !== updatedItem._id);
        }
        return prevItems.map(item => 
          item._id === updatedItem._id ? updatedItem : item
        );
      });
    });

    socket.on('itemDeleted', (itemId) => {
      setItems(prevItems => prevItems.filter(item => item._id !== itemId));
    });

    socket.on('storeUpdated', (updatedStore) => {
      setStores(prevStores => prevStores.map(store => 
        store._id === updatedStore._id ? updatedStore : store
      ));
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Initial data fetch
  useEffect(() => {
    fetchItems();
    fetchStores();
  }, []);

  // Filter and sort items when dependencies change
  useEffect(() => {
    filterAndSortItems();
  }, [searchTerm, selectedStore, items, stores]);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/items`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      // Only set items with "Have" status
      setItems(response.data.filter(item => item.status === 'Have'));
    } catch (error) {
      console.error('Error fetching items:', error);
      toast({
        title: "Error fetching items",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchStores = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/stores`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStores(response.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
      toast({
        title: "Error fetching stores",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filterAndSortItems = () => {
    let filtered = [...items];

    // Filter by store
    if (selectedStore !== 'all') {
      filtered = filtered.filter(item => item.store._id === selectedStore);
    }

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Sort by store name first, then by item name
    filtered.sort((a, b) => {
      // First sort by store name
      const storeCompare = a.store.storeName.localeCompare(b.store.storeName);
      if (storeCompare !== 0) return storeCompare;
      
      // If same store, sort by item name
      return a.itemName.localeCompare(b.itemName);
    });

    setFilteredItems(filtered);
  };

  const handleStatusChange = async (itemId, newStatus) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/items/${itemId}`,
        { status: newStatus },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      
      if (newStatus === 'Need') {
        setItems(prevItems => prevItems.filter(item => item._id !== itemId));
      } else {
        setItems(prevItems =>
          prevItems.map(item =>
            item._id === itemId ? response.data : item
          )
        );
      }

      toast({
        title: `Item marked as ${newStatus}`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleItemUpdate = async (updatedItem) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/items/${updatedItem._id}`,
        updatedItem,
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      if (response.data.status === 'Have') {
        setItems(prevItems =>
          prevItems.map(item =>
            item._id === updatedItem._id ? response.data : item
          )
        );
      } else {
        setItems(prevItems =>
          prevItems.filter(item => item._id !== updatedItem._id)
        );
      }

      toast({
        title: "Item updated successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleItemDelete = async (itemId) => {
    try {
      await axios.delete(`${apiBaseUrl}/items/${itemId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });

      setItems(prevItems => prevItems.filter(item => item._id !== itemId));

      toast({
        title: "Item deleted successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <HStack spacing={4}>
          <InputGroup size="lg" flex="2">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search items..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              bg="gray.700"
              color="white"
              _placeholder={{ color: 'gray.400' }}
              borderRadius="md"
            />
          </InputGroup>

          <Select
            value={selectedStore}
            onChange={(e) => setSelectedStore(e.target.value)}
            bg="gray.700"
            color="white"
            size="lg"
            flex="1"
            borderRadius="md"
            sx={{
              '& option': {
                color: 'white',
                backgroundColor: 'gray.700'
              }
            }}
          >
            <option value="all">All Stores</option>
            {stores.sort((a, b) => a.storeName.localeCompare(b.storeName)).map(store => (
              <option key={store._id} value={store._id}>
                {store.storeName}
              </option>
            ))}
          </Select>
        </HStack>

        {filteredItems.length === 0 ? (
          <Text color="gray.400" textAlign="center" py={8}>
            No items in pantry. Items marked as "Have" will appear here.
          </Text>
        ) : (
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)"
            }}
            gap={6}
          >
            {filteredItems.map(item => (
              <ItemCard
                key={item._id}
                item={item}
                onClick={() => {
                  setSelectedItem(item);
                  onOpen();
                }}
                onStatusChange={handleStatusChange}
              />
            ))}
          </Grid>
        )}

        {selectedItem && (
          <ItemModal
            isOpen={isOpen}
            onClose={() => {
              setSelectedItem(null);
              onClose();
            }}
            item={selectedItem}
            onUpdate={handleItemUpdate}
            onDelete={handleItemDelete}
          />
        )}
      </VStack>
    </Container>
  );
}

export default PantryList;
